@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";

.bigRoundedButton {
  border-radius: 5px;
  width: 100%;
  font-size: 1.2rem;
  background-color: $admin-green-dark;
  color: #fff;
  padding: 15px;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: none;
  }

  span {
    margin-right: 10px;
  }

  a {
    color: inherit;
  }

  &Disabled {
    @include mixins.disabledButton;
  }
}
