@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

.mainFooter {
  position: relative;
  background: #343430;
  color: $background-color;
  transition: all ease .5s;
  padding-top: 2rem;

  a {
    color: inherit;
  }

  a, button {
    outline-offset: 4px;
  }

  .navContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 50px;

    @media(max-width: $break-point-sm) {
      margin: 0;
    }
  }

  &ContentWell {
    display: inline-block;
    vertical-align: top;

    padding: 0;

    width: 100%;
    @include mixins.minScreenMd {
      width: 33%;
    }
  }

  .organizationInfo {
    padding: 4rem 0;

    > h3, div, small {
      line-height: 2rem;
    }

    .footerContactInfo {
      color: #BAB8A0;
      font-size: 1.4rem;
    }
  }
}

.footerColumn {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  padding: 0 20px;
  margin: 10px 0;

  h3 {
    font-weight: 700;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
      padding: 5px 0;
      display: table;

      a, button {
        color: inherit;

        > span {
          padding-right: 10px;
          width: 20px;
          text-align: center;
        }
      }
    }
  }
}

.addressColumn {
  composes: footerColumn;

  > div {
    padding-top: 10px;

    > div {
      display: inline-block;

      &:first-of-type {
        padding-right: 20px;
        vertical-align: top;
      }
    }
  }

  a {
    color: inherit !important;
  }
}

.navColumn {
  composes: footerColumn;
  width: 255px;
  border-left: 1px solid #494a4d;

  h3 {
    text-transform: uppercase;
  }

  a {
    color: inherit;
  }

  span[class*=sbicon-] {
    &::before {
      margin: 0;
    }
  }
}

.socialColumn {
  composes: footerColumn;
  width: 255px;
}

.adminColumn {
  composes: footerColumn;
  width: 255px;
  border-left: 1px solid rgb(198, 11, 46);

  li {
    a {
      color: inherit;
    }
  }
}

.descriptionColumn {
  composes: footerColumn;

  > div {
    padding-top: 10px;

    > div {
      display: inline-block;

      &:first-of-type {
        padding-right: 20px;
        vertical-align: top;
      }
    }
  }

  a {
    color: inherit !important;
  }

  border-left: 1px solid #494a4d;
  overflow: hidden;
  word-break: break-word;
}

.footerText {
  text-align: left;
  padding-left: 70px;
  padding-bottom: 2rem;

  @media(max-width: $break-point-sm) {
    padding-left: 20px;
    padding-right: 15vw;
  }
}

.footerTextSaveIcon {
  opacity: 0;
  transition: opacity 1.5s ease;

  &DidSave {
    opacity: 1;
  }
}
.followFooter {

  button{
    &:hover, &:focus{
      background-color: transparent;
      color: inherit;
    }
    padding: 0;
    margin:0;
    background-color: transparent;

    span[class*=fa-], div {
      display: inline-block;
      font-size: 14px;
    }

    span[class*=fa-] {
      margin-top: 5px;
    }

  }

}
