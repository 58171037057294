@use '../../../styles/variables.module' as *;
@use 'navigation.module';
@use '../../../styles/zIndex.module' as zIndex;


.primary {
  composes: navigation;
  composes: animatedTransition;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.schoolBlocksHeader {
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  transition: opacity .5s ease;
  z-index: zIndex.$z-index-navigation-primary;

  &WelcomePage {
    @media(max-width: $break-point-lg) {
      position: relative;
      opacity: 1 !important;
    }
  }

  @media(max-width: $break-point-lg) {
    // when hero is enabled, the header translates in from top and should not take up space. otherwise, header is relative
    // and stays on top of the page on mobile. may need to re-evaluate this in the future if resulting in poor mobile experience
    // for terraced grid pages with hero.
    &:not(&HeroIsEnabled) {
      position: relative;
    }
  }

  &HeroIsEnabled {
    opacity: 0;
  }

  &Visible {
    opacity: 1;
  }
}
