@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";
@use "../../../styles/zIndex.module" as zIndex;

.container {
  height: auto;
  width: 100%;
  background-color: var(--secondaryNavBackground);
  color: var(--secondaryNavBackgroundTextColor);
  bottom: 0;
  position: fixed;
  z-index: zIndex.$z-index-mobile-nav;
  transition: bottom .25s ease;
  border-top: 1px white solid;

  &Hidden {
    bottom: -100%;
  }

  @media (min-width: $break-point-lg) {
    display: none;
  }
}

.unorderedList {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  column-gap: 20px;
}

.listItem {
  display: inline-block;
  text-align: center;
  margin: $mobile-list-margin-top $mobile-list-margin-right $mobile-list-margin-bottom  $mobile-list-margin-left;
  overflow: visible;
}

.menuButton {
  color: inherit !important;
  cursor: pointer;
  text-decoration: none !important;
  height: 50px;

  .notifications {
    position: relative;
    margin: 0 auto;

    > span {
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  > span {
    display: grid;
  }

  &:focus {
    @include mixins.focusOutline;
  }
}

.menuItemName {
  color: inherit !important;
  grid-row: 2;
}

.notifications {
  span:first-of-type {
    font-size: 2em;
  }
}