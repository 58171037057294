@use "../../../styles/variables.module" as *;

.section {
  width: 100%;
  background-color: #F2F4F7;
  border-radius: 25px;
  margin: 10px 0px 10px 0px;
  padding: 10px 5px;
  font-weight: 600;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0 10px;
  }

  .sectionInfo {
    color: $gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &NoContent {
      color: rgba(84, 84, 84, .6);
    }
  }

  &:hover {
    .sectionInfo {
      color: lighten($gray, 20%);
    }
  }
}

.orgContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.externalLink {
  margin-left: 30px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  height: 45px;
  margin-right: 10px;
}

.title {
  font-size: 20px;
  margin-inline: 5px;
  width: 100%;
}

.type {
  font-size: 16px;
  font-weight: normal;
  margin-inline: 5px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.followButton {
  color: #FFD700;
}

.hiddenWhenSmall {
  @media(max-width: $break-point-sm) {
    display: none;
  }
}

@media (max-width: 620px) {
  .title {
    font-size: 14px;
    margin-inline: 2px;
  }

  .type {
    font-size: 12px;
    margin-inline: 2px;
  }

  .icon{
    margin-right: 10px;
  }
}

@media (max-width: 320px) {
  .buttonContainer {
    width: 10%;
  }

  .sectionInfo {
    width: 100%;
  }

  .title {
    font-size: 10px;
    margin-inline: 2px;
  }

  .type {
    font-size: 10px;
    margin-inline: 2px;
  }

  .icon{
    margin-right: 10px;
  }
}

