@use "../../../styles/variables.module" as *;

.poweredByContainer {
  width: 100%;
  text-align: center;
  color: $background-color;
  background-color: #1A1A1A;
  line-height: 5rem;
  font-weight: 100;
  padding-bottom: 50px;

  a {
    color: inherit;
  }

  span {
    > a {
      font-weight: 700;
    }
  }

  .loginButton {
    cursor: pointer;
    display: inline-block;
    line-height: 1rem;
    background-color: $gray;
    color: #fff;
    margin: 0 5px;
    padding: 15px 20px;
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .manageButton {
    background-color: $theme-color-green;
    color: $black;
    cursor: pointer;
    display: inline-block;
    line-height: 1rem;
    margin: 0 5px;
    padding: 15px 20px;
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
