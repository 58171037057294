@use "../../../styles/mixins";

@mixin menuButtonMixin {
  border-radius: 20px;
  vertical-align: middle;
  line-height: 25px;
  @include mixins.leftToRightColorTransition(var(--secondaryNavBackground), var(--secondaryNavBackgroundTextColor));

  &:hover{
    span {
      svg {
        fill: var(--secondaryNavBackgroundTextColor);
      }
    }
  }
}

.container {
  position: absolute;
  width: 100%;
  height: 500px;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: top;
  pointer-events: none;
  margin-top: -10px;
  padding-inline: 5%;
  line-height: unset;

  ul {
    width: 90%;
    position: absolute;
    background: white;
    text-align: left;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    transform: translateY(-100%);
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 22%);
    border-radius: 0px 0px 10px 10px;

    li {
      padding: 0px 10px;
      width: 100%;
      &:hover {
        background: lightgrey;
      }
      &:last-of-type {
        border-radius: 0px 0px 10px 10px;
      }
      a, button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 6px 0px;
        text-align: left;

        div {
          width: 15%;
          span:first-of-type {
            vertical-align: middle;
            text-align: center;
          }
        }
        > span:last-of-type {
          vertical-align: middle;
          color: black;
          width: 80%;
          display: inline-block;
        }
      }
      .icon[class*="fa-"] {
        color: black;
      }
      &:first-of-type {
        padding-top: 10px;
      }
      button {
        padding-top: 4px;
      }
    }
  }

  &Open {
    ul {
      transform: translateY(0%);
      pointer-events: all;
    }
  }
}

.userMenu {
  position: relative;

  .userMenuButton {
    display: block;
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
    min-width: 170px;
    @include menuButtonMixin;

    .notificationIcon { // notifications icon
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }
  div {
    display: inline-block;
  }
}
