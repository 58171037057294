@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

.container {
  background: white;
  outline: black;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.22);
  margin-right: 15px;
  margin-left: ($block-margin-horizontal * 0.5);
  border-bottom-left-radius: $block-border-radius;
  border-bottom-right-radius: $block-border-radius;
  height: 100%;

  > div:nth-of-type(2) {
    margin-left: 16px;
    > button {
      // add section button
      background: $admin-blue-dark;
      font-weight: bold;
      color: white;
      padding: 8px 10px;
      font-size: 1.6rem;
      border-radius: 10px;
      display: block;
      margin: 8px 0;
    }
  }

  .linksContainer {
    background: inherit;
    padding-left: 0;
    list-style: none;

    button {
      margin-left: -14px;
      margin-right: 14px;
      font-size: 1.6rem;
    }
  }

  .link {
    font-size: 1.3rem;
    text-shadow: none;
    line-height: 2.2rem;
    margin: 0 8px;
    padding: 8px 0;
    font-weight: 500;

    &Dragging {
      background: #ddddfc;
      border: 1px dashed #4040fa !important;
      margin:2px 0;
    }

    &IsCurrentPage {
      font-weight: 700;

      > span {
        background: #e9e9e9;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid lightgray;
    }

    > a, > span {
      // this is not the current page link
      color: $black;
      text-decoration: none;
      padding: 6px 8px;
      border-radius: 10px;
      display: inline-block;
      width: 100%;

      &:hover {
        background: #e9e9e9;
      }

      span:nth-of-type(1) {
        // title
        vertical-align: middle;
        width: calc(100% - 25px);
        display: inline-block;
      }
      span:nth-of-type(2) {
        vertical-align: middle;
        width: 25px;
        display: inline-block;
        text-align: right;
      }
    }

    ul {
      list-style: none;
      padding-left: 15px;
      margin-top: 8px;
      border-top: 1px solid lightgray;

      li {
        margin: 0;
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    &Reordering {
      a {
        cursor: move;
      }
      span:nth-of-type(1), span:nth-of-type(3) {
        display: inline-block !important;
        width: 25px !important;
      }
      span:nth-of-type(2) {
        text-align: left !important;
        width: calc(100% - 50px) !important;
      }
    }
  }
}

.closeButtonContainer {
  display: none;
  text-align: right;

  &IsOpen {
    display: block;
  }
}

.reorderButton {
  &Enabled {
    background-color: $gray !important;
  }
}

.toggle {
  text-align: center;
  position: relative;
  font-size: 1.2rem;
  padding: 5px 0;
  margin: 0px 4px 4px 4px;
  color: rgb(77, 77, 77);
  line-height: normal;

  span {
    font-size: 1.5rem;
  }

  .tooltip {
    position: relative;
    margin-bottom: -3px;

    &::after, &::before {
      content: "";
      opacity: 0;
      transition: opacity .25s ease;
    }

    &:hover {
      &::after {
        opacity: 1;
        background: black;
        content: attr(data-content);
        position: absolute;
        color: white;
        margin-left: 10px;
        z-index: 1;
        font-size: 1rem;
        padding: .5rem;
        white-space: pre;
        border-radius: 5px;
        transform: translateY(-50%);
        top: 50%;
        text-align: center;
        font-weight: 100;
        right: calc(100% + 10px);
      }

      &::before {
        content: "";
        opacity: 1;
        background: black;
        width: 10px;
        position: absolute;
        height: 10px;
        transform: translateY(-50%) rotate(45deg);
        top: 50%;
        right: calc(100% + 5px);
      }
    }
  }
}
