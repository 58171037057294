@use '../../../styles/variables.module' as *;
@use '../../../styles/zIndex.module' as zIndex;

$language-color: #4c4c4c;

.container {
  top: 0;
  height: 0;
  display: flex;
  justify-content: center;
  z-index: zIndex.$z-index-translation-tool + 2;
  color: black;
  width: 100%;
  @media(min-width: $break-point-lg) {
    position: fixed;
  }
  @media(max-width: $break-point-lg) {
    justify-content: flex-end;
    right: 0;
  }
}

.languageButtonContainer {

  z-index: zIndex.$z-index-translation-tool + 2;
  height: 30px;
  width: 216px;
  justify-content: center;
  display: flex;
  background: white;
  clip-path: polygon(0 0, 50px 100%, calc(100% - 50px) 100%, 100% 0);
  cursor: pointer;
  color: black;

  span {
    pointer-events: none;
  }

  &:hover, &:focus {
    background: $language-color !important;
    color: #ffffff !important;
    outline: none;
    text-decoration: underline;
  }

  @media(max-width: $break-point-lg) {
    justify-content: flex-end;
    clip-path: polygon(70px 0, 112px 100%, 100% 100%, 100% 0);

    > span {
      padding-right: 15px;
      div {
        padding-right: 10px !important;
      }
    }
  }

  > span {
    div {
      padding: 5px 0 5px 10px;

      span {
        transition: transform .1s ease;
      }
    }
  }
}

.whiteText {
  color: #ffffff;
  background: $language-color;
}

.languageButtonShadow {
  top: 0;
  position: absolute;
  z-index: zIndex.$z-index-translation-tool + 1;
  width: 218px;
  height: 31px;
  opacity: 0.4;
  clip-path: polygon(0 0, 51px 100%, calc(100% - 51px) 100%, 100% 0);
  background: $language-color;

  @media(max-width: $break-point-lg) {
    clip-path: polygon(70px 0, 113px 100%, 100% 100%, 100% 0);
  }
}

.translationElement {
  top: 0;
  width: 650px;
  position: absolute;
  background-color: $background-color;
  border: 1px solid $border-color;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: zIndex.$z-index-translation-tool;

  @media(max-width: $break-point-lg) {
    width: 450px;
  }
  @media(max-width: $break-point-sm) {
    width: 320px;
    height: auto;
  }
}

.inner {
  margin: 50px 50px 30px 50px;
  line-height: 1.5em;
  height: 100%;

  @media(max-width: $break-point-md) {
    margin: 50px 30px 20px 30px;
  }

  @media(max-width: $break-point-sm) {
    margin: 50px 20px 20px 20px;
  }
}

.selectContainer {
  padding-bottom: 10px;

  h3 {
    font-weight: bold;
  }
}

.icon {
  margin-right: 10px;
  opacity: 0.5;
  float: left;
}

.checkBoxIcon {
  margin: 5px 10px 0 0;

  &.transparent {
    opacity: 0;
  }
}

.learnMoreContainer {
  padding-bottom: 10px;
}

.dropdownContainer {
  padding-top: 20px;
  padding-bottom: 10px;

  h4 {
    font-weight: bold;
    padding-bottom: 10px;
  }
}

.dropdownButton {
  height: 15%;
  width: 100%;
  color: black !important;
  background-color: transparent !important;
  border: 1px solid $border-color !important;
  font-weight: normal;
  text-align: left !important;

  &:focus {
    outline-width: 7px;
    outline-style: solid;
    outline-color: red;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
  }
}

.dropdownMenu {
  width: 100%;
  font-weight: normal;
  height: 150px;
  overflow: auto;
}

.dropdownItem {
  display: block;
  color: black;
  padding-left: 10px;

  &:hover, &:focus {
    background-color: #4a94f3;
    color: #ffffff;
  }
}

.sortDown {
  float: right;
}

.heading1 {
  font-weight: bold;
  font-size: 22px;
}

.heading2 {
  font-weight: bold;
  font-size: 19px;
  padding-bottom: 10px;
}
