@use "../../../styles/variables.module";

.notificationIcon {
  background: variables.$error-red;
  color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  font-size: 12px !important;
  vertical-align: middle;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  display: inline-block;
}