.container {
  text-align: center;
  display: flex;
  justify-content: center;

  > div {
    background: rgb(219, 234, 254);
    border: 1px solid rgb(191, 219, 254);
    padding: 1rem 3rem;
    border-radius: 10px;
    margin: 1rem;
    font-size: 1.3rem;
    align-items: center;
    justify-content: center;
    width: max-content;
  }
  button {
    margin-top: 1rem;
    width: 10rem;
  }
}