@use "../../../styles/variables.module" as *;

.container {
  background: white;
  border-bottom: 1px solid lightgrey;

  .micrositeContainerOverlayRight {
    width: 40px;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    z-index: 1;
    pointer-events: none;

    &WithArrows {
      right: 25px;
    }
  }

  div {
    position: relative;
  }

  > div {
    margin-inline: $terrace-outer-margin;
    padding: 20px 0px 20px 0px;

    &:nth-of-type(2) {
      border-top: 1px solid lightgrey;
    }

    .micrositeContainer {

      > button {
        font-size: 2.5rem;
        vertical-align: middle;
        margin-left: .5rem;

        &:last-of-type {
          margin-right: 40px;
        }
      }
    }
    @media (max-width: $break-point-sm) {
      margin-inline: 10px;
    }
  }
}

.title {
  color: #000;
  font-weight: 500;
  font-size: 1.8rem !important;
  @media(min-width: $break-point-sm) {
    display: inline-block;
  }


  a {
    color: #000;
  }

  span[class*="fa-"] {
    margin: 0 0.5rem;
    font-size: 1.8rem;
  }

  span[class*="fa-star"] {
    margin: 0 0.5rem 0 0;
  }
}


.title + .restoreButton {
  margin-left: 25px;
}

.followButton {
  color: #FFD700;
  float: right;
}

.breadcrumbSelected {
  @media (max-width: $break-point-sm) {
    max-width: 85%;
  }
  color: black;
  font-weight: bold;
  display: inline-block;
}

.micrositeContainer {
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  //For FireFox
  scrollbar-color: transparent transparent;

  //For Chrome / Safari
  &::-webkit-scrollbar {
    width: 0px; /* Adjust the width as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.microsite {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  background-color: #F2F4F7;
  color: black;
  margin: 0px 5px 0px 5px;
  font-weight: 600;
  scroll-snap-align: start;

  &:first-of-type {
    margin: 0px 5px 0px 0px;
  }

  &CanDrag {
    cursor: move;
  }
}

.scrollLeft {
  position: absolute;
  z-index: 20;
  left: 5px;
  top: 40%;
  transform: translate(0%, -50%);
  &Hidden {
    display: none;
  }
}

.scrollRight {
  position: absolute;
  top: 40%;
  transform: translate(0%, -50%);
  z-index: 20;
  right: 5px;
  &Hidden {
    display: none;
  }
}
.addSubPageInitial{
  vertical-align: text-bottom;
  display: inline-block;
  padding: 0 5px;
  font-size: 1.3rem;
  border: 2px solid var(--secondaryNavBackground);
  border-radius: 5px;
  margin-left: 10px;
}


