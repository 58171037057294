// breakpoints

$S:     480px;
$M:     600px;
$L:     1000px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; }
  }
}

// super light grid system

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}
