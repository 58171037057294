@use "../../../../../styles/variables.module" as *;
@use "mixins.module";
@use "layout.module";
@use "variables.module";

.cdMorphDropdown {
  position: relative;
  text-align: center;
  z-index: 10;
  line-height: 10px;

  &::before {
    /* never visible - used in JS to check mq */
    content: 'mobile';
    display: none;
  }
}

.mainNav {
  display: inline-block;
  height: inherit;
  width: 100%;

  > ul {
    padding: 0;
    margin: 0;

    > li {
      display: inline-block;
    }
  }
}

.mainLink {
  font-size: 1.4rem; // this  matches old schoolblocks styling of 1.2em (16.8px computed)
  margin-left: 0.7rem;

  > a, > button {
    margin-left: 3px;
    padding: 0 10px;
  }

  a, button {
    display: inline-block;
    height: $navbar-container-height;
    line-height: $navbar-container-height;
    @include mixins.font-smoothing;
    transition: all .2s;
    cursor: pointer;
    color: var(--secondaryNavBackgroundTextColor);
    font-family: var(--fontFamily);
  }

  > a > span, > button > span {
    line-height: $navbar-container-height;
    vertical-align: bottom;

    &.icon {
      padding-right: .6em;
    }

    &.popupArrow {
      width: 10px;
      margin-left: 8px;
      transition: transform .25s ease;
    }
  }
}

.highlight {
  background-color: var(--primaryNavBackground) !important;
  button {
    color: var(--primaryFontColor) !important;
  }
}

.addNewLink {
  display: inline !important;
  background-color: var(--darkerBlocks) !important;
  width: auto;
  border-radius: 10px;
  padding: 0 20px !important;
  color: var(--blocksTextColor) !important;
  bottom: 5px;
  height: 40px !important;
  line-height: 40px !important;
  margin-top: 10px;
}

.scrollIndicator {
  background-color: var(--darkerBlocks) !important;
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  z-index: 11;
}

.apiProgressIndicator {
  composes: scrollIndicator;
  bottom: 0;
  top: unset;
  transition: width .25s ease, opacity .25s ease;
}
