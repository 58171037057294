@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

.inlineBlock {
  display: inline-block;
}

.mainHeader {
  transition: all 300ms ease;
  background-color: var(--primaryNavBackground);
  @include mixins.opacityTransition(0, 1);
}

.skip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: .857143em;

  li {
    position: absolute;
    z-index: 1;
    margin: 0;
    list-style: none;

    a {
      position: absolute;
      left: -9999px;
    }

    a:focus {
      left: 0;
      top: 0;
      padding: 3px;
      background: #ffc;
      border:1px solid #990000;
    }
  }
}

.heroContainer {
  transition: all 250ms ease;
}

.titleAndSocial {
  width: 85%;
  text-align: left;
  padding-left: 15px;

  &TitleInvisible {
    h1 {
      visibility: hidden;
      position: absolute;
    }
  }

  h1 {
    display: inline;
    &:hover {
      color: inherit;
    }
  }

  @media(max-width: $break-point-lg) {
    width: 100%;
  }
}

.searchIcon {
  margin-right: 10px;
  height: 25px;
  width: 25px;
  float: right;
}

.searchForm {
  float: right;

  .speech {
    pointer-events: all;
    right: 25px;
    // from here down its form-control-feedback
    position: absolute;
    top: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
  }
  .eyeglass {
    pointer-events: all;
    // from here down its form-control-feedback
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
  }
    .formGroup {
      margin-bottom: 0;

      input::placeholder {
        color: #4e4e4e;
      }
    }
    .searchText {
      width: 300px;
      border-radius: 4px;

      -webkit-transition: width .5s;
      -moz-transition: width .5s;
      transition: width .5s;

      &:focus {
        box-shadow: none;
        background-color: #ffff;
        border-color: #aaa;
        color: #333;
        outline-style: none;
      }

      @media (max-width: $break-point-lg) {
        width: 304px;
        padding-right: 29px;
      }
    }
}

.darkIcon {
  color: #4e4e4e !important;
}
