@use "variables.module" as dropdownVariables;
@use "mixins.module" as dropdownMixins;
@use "layout.module";
@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

$column-width: 290px;
$outer-padding: 30px;

:export {
  column-width: $column-width;
  outer-padding: $outer-padding;
}

// hidden by default; when a user clicks the menu icon, the .isVisible class is added to reveal the navigation.
.morphDropdownWrapper {
  display: none;
  position: absolute;
  top: $navbar-container-height;
  left: 0;
  width: 100%;
  //padding: 1.2em 5%;
  box-shadow: inset 0 1px 0 dropdownMixins.shade(dropdownVariables.$color-3, 10%);
  background-color: dropdownVariables.$color-3;

  @include layout.MQ(L) {
    /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
    display: block;
    /* overwrite mobile style */
    width: auto;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    /* Force Hardware acceleration */
    transform: translateY(0);
    will-change: transform;
    transition: transform .3s;
  }
}

.dropdownList {
  transition: transform .3s, width .3s, height .3s;
  background-color: #FFF;

  padding-top: 10px;

  > ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    > li {
      //margin-bottom: 3.3em;
    }
  }

  &Above {
    @include layout.MQ(L) {
      // place triangle on bottom when dropdown is above
      &::before {
        border-bottom: 0 !important;
        border-top: 10px solid dropdownVariables.$color-3 !important;
        bottom: 0 !important;
        top: 100% !important;
      }
    }
  }

  @include layout.MQ(L) {
    //position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);
    will-change: transform, width, height;
    transition: transform .3s ease;
    box-shadow: 0 10px 20px rgba(#333, .08);

    .no-csstransitions & {
      display: none;
    }

    //&::before {
    //    /* dropdown top triangle */
    //    content: '';
    //    position: absolute;
    //    bottom: 100%;
    //    @include center(x);
    //    height: 0;
    //    width: 0;
    //    border: 8px solid transparent;
    //    border-bottom-color: $color-3;
    //    opacity: 0;
    //    transition: opacity .3s;
    //}

    > ul {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
      list-style: none;
    }

    //transition: transform .3s ease;
  }
}

.dropdown {
  //position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  transition: opacity .3s, visibility .3s;
  padding: $outer-padding;
  @include dropdownMixins.clearfix;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  //&.moveLeft .content {
  //    transform: translateX(-100px);
  //}
  //
  //&.moveRight .content {
  //    transform: translateX(100px);
  //}
}


.label {
  display: block;
  font-size: 2.2rem;
  color: dropdownVariables.$color-1;
  margin-bottom: .8em;

  @include layout.MQ(L) {
    /* hide the label on bigger devices */
    display: none;
  }
}

.bgLayer {
  /* morph dropdown background */
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  background: dropdownVariables.$color-3;
  transform-origin: top left;
  transform: translateZ(0);
  will-change: transform;
  backface-visibility: hidden;
  transition: transform .3s, opacity .3s;
}

// styles for all content
.content {
  a:focus, h2:focus, button:focus {
    @include mixins.focusOutline;
    outline-offset: 4px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      @include dropdownMixins.clearfix;
      //&:nth-last-child(1):first-child,
      //&:nth-last-child(1):first-child ~ * {
      //    width: 100%;
      //}
      //&:nth-last-child(2):first-child,
      //&:nth-last-child(2):first-child ~ * {
      //    width: 50%;
      //}
      //&:nth-last-child(3):first-child,
      //&:nth-last-child(3):first-child ~ * {
      //    width: 33%;
      //}
    }
  }


  @include layout.MQ(L) {
    transition: transform .3s;
    text-align: left;

    ul {
      @include dropdownMixins.clearfix;

      li {
        //@include column(.48);
        //margin-right: 4%;
        width: $column-width;
        display: inline-block;
        vertical-align: top;
        margin-top: 0;

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }

  h2 {
    color: dropdownMixins.shade(dropdownVariables.$color-3, 35%);
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 1.2rem;
    margin: 20px 0 10px 14px;

    @include layout.MQ(L) {
      text-transform: none;
      color: dropdownVariables.$color-1;
      margin: 0 0 .6em;
    }
  }

  li > a, li > span, li > button{
    //color: var(--blocks);
    color: #4D4C40 !important;
    font-family: var(--fontFamily);

    &:active {
      color: #4D4C40 !important;
    }

    //&:hover {
    //    color: #333;
    //}
  }

}


.gallery {
  .content {
    li {
      margin-bottom: 1.4em;
      text-align: left;
      padding-right: 5px;

      > a, > span, > button {
        display: block;
        //color: var(--blocks);
        color: #4D4C40 !important;
        font-family: var(--fontFamily);
        line-height: 30px;

        &:active {
          color: #4D4C40 !important;
        }

        &:hover {
          color: #333;
        }

        &:hover::before {
          background-color: dropdownVariables.$color-1;
        }
      }
    }

    @include layout.MQ(L) {
      /* you need to set a width for the .content elements because they have a position absolute */
      padding-bottom: .8em;

      li {
        margin-bottom: 1.6em;
      }
    }
  }
}

.links .content {
  ul li {
    margin-top: 0;
    padding: 6px 0;

    a, button {
      line-height: 17px;
    }
  }

  > ul {
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
  }

  > ul > li {
    padding-right: 10px;
    padding-left: 40px;
    font-size: 1.3rem;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    h2 {
      font-size: 1.5rem;
    }

    > ul > li {
      width: 239px;
    }

    &:not(:last-of-type) {
      // in case of multiple rows, don't show borders at end of each row
      border-right: 1px solid #e2e3df;
    }

    &:last-of-type {
      border-right: none !important;
    }
  }
}

.links .content,
.button .content {
  //width: 390px;
}

.links-list a, .links-list button {
  font-size: 1.6rem;
  margin-left: 0;
}

.dropdownArrow {
  top: -6px;
  position: absolute;
  margin: 0 0 0 -6px;
  width: 12px;
  height: 12px;
  border-radius: 4px 0 0 0;
  background: #fff;
  display: block;
  transition: all .3s ease;
}

.dropdownIconLink {
  padding-top: .4rem;

  > div {
    display: inline-block;

    &:first-of-type { // container for icon
      padding-right: .5em;
      vertical-align: top;
      width: 30px;
      text-align: center;

      span {
        font-size: 1.8rem;
        vertical-align: middle;
      }
    }

    &:last-of-type { // container for title/description
      width: calc(100% - 30px);

      h3, span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      h3 {
        font-size: 1.8rem;
        //padding: 0 0 .2em;
        font-weight: 700;
        line-height: inherit;
      }

      span {
        display: block;
        color: #4D4C40 !important;
      }
    }
  }
}

.dropDownDetails {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover span {
    visibility: visible;
  }
}

.popOutIcon {
  visibility: hidden;
}
