@use "../../../styles/variables.module" as *;

.container {
  position: relative;
  z-index: 2;

  .popup {
    position: absolute;
    bottom: calc(100% + 10px);
    padding: 15px;
    background: white;
    border-radius: 10px;
    box-shadow: $box-shadow;
    width: 300px;
    max-width: 100vw;

    textarea {
      color: black;
      width: 80%;
      float: left;
      font-family: inherit;
    }

    div:nth-of-type(1) { // buttons container
      text-align: center;
      width: 20%;
      float: left;
    }

    div:nth-of-type(2) { // arrow
      position: absolute;
      transform: rotate(45deg);
      width: 20px;
      height: 20px;
      bottom: -10px;
      background: white;
      left: calc(50% - 10px);
    }

    button {
      padding: 5px;
      color: white;
      width: 30px;
      height: 30px;
      margin: 0 5px;
      border: 1px solid $border-color;
      border-radius: 5px;
      box-shadow: $box-shadow;

      span {
        vertical-align: text-top;
      }

      &:first-of-type {
        background-color: $admin-blue-dark;
      }

      &:last-of-type {
        background-color: white;
        color: $black;
      }
    }
  }
}
