@use "../../styles/variables.module" as *;
@use "../../styles/mixins";

.container {
  position: relative;
  $micrositeWidth: 300px;

  .grid {
    display: grid;
    width: 100vw;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    margin-bottom: 1rem;

    &WithMicrosite {
      @media (min-width: $break-point-md) {
        grid-template-columns: $micrositeWidth auto;

        .microsite {
          display: block !important;
        }

        .classroomHeaderTopBlock {
          border-bottom-left-radius: 0;
        }

        .packery {
          grid-column-start: 2 !important;
          grid-column-end: 3 !important;
        }

        .header {
          grid-column-end: 3 !important;
        }
      }
    }

    &GridPage {
      margin-top: 1rem;

      @include mixins.minScreenMd {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }



    .packery {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: auto;
    }

    .header {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .microsite {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: auto;
      position: relative;

      @media(max-width: $break-point-md) {
        position: fixed;
        left: -$micrositeWidth;
        display: block;
        visibility: hidden;
        z-index: 1;
        margin-top: -1rem;
        transition: left .25s ease, visibility .25s;
        top: $primary-nav-height + 10px;
        bottom: $mobile-list-item-height + $mobile-list-margin-top + $mobile-list-margin-bottom;

        > div {
          margin-left: 0;
          width: $micrositeWidth;
          padding: 10px 10px 10px 0;
          overflow-y: auto;
        }
      }

      &Terrace {
        position: fixed;
        left: -$micrositeWidth;
        display: block;
        visibility: hidden;
        z-index: 900;
        margin-top: -1rem;
        transition: left .25s ease, visibility .25s;
        top: $primary-nav-height + $navbar-container-height + 12.5px;
        bottom: 0px;

        > div {
          margin-left: 0;
          width: $micrositeWidth;
          padding: 10px 10px 10px 0;
          overflow-y: auto;
        }

        @media(max-width: $break-point-md) {
          top: $primary-nav-height + 12.5px;
        }
      }

      &Open {
        visibility: visible;
        left: 0;
      }
    }
  }

  .fullWidthPage {
    margin-bottom: 0;
    max-width: 100vw;
    width: auto;

    &SubPage {
      margin-top: 0;
    }
  }
}

.classroomTeacher {

  @media (max-width: $break-point-sm) {
    display: none;
  }

  .classroomTeacherTitle {
    text-align: center;
    vertical-align: middle;

    a, a:hover, a:focus, a:visited {
      outline: none;
      text-decoration: none;
    }
  }

  .classroomTeacherImage {
    height: 26px;
    width: 26px;
    margin: 0 auto;
  }

  .classroomMultipleTeachers {
    display: inline-block;
    padding: 5px 12px;
    margin: 4px 0;
    line-height: normal;
    text-align: center;

    a:focus, a:hover, a:visited {
      text-decoration: none;
      outline: none;
    }
  }
}

.classroomHeaderLabels {
  display: block;
  position: relative;
  height: auto;
  text-align: center;
}

.classroomLinkedFloatingButton {
  border-style: none;
  font-size: 1em;
  padding: 5px 12px;
  margin: 4px 0;
  line-height: normal;

  img {
    height: 26px;
  }
}

.classroomFollowFloatingButton {
  padding: 5px 12px;
  border-style: none;
  margin: 4px 0;
  font-size: 1.3rem;
  color: rgb(77, 77, 77);
  line-height: normal;

  > div {
    font-size: 1.2rem;
  }

  span[class*="fa-"] {
    color: #FFD700;
    font-size: 26px;
    vertical-align: top;
  }
}

.teachersLabel {
  font-size: 1.2em;
  margin-right: 1em;
}

.micrositeToggleButton {
  vertical-align: bottom;
  margin-right: .5rem;

  @media(min-width: $break-point-md) {
    display: none;
  }

  &Terrace{
    display: inline-block;
  }
}

.loginWrapper {
  margin-top: 2rem;
}

.classroomHeaderTopBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 4em;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
  margin: 0 ($block-margin-horizontal * 0.5);
  padding: 0 1em;
  font-weight: normal;
  border-radius: $block-border-radius;
  flex-wrap: wrap;

  .rightAlignedHeader {
    display: flex;
    align-items: center;
  }

  .breadcrumbs {
    display: inline-block;
  }

  .classroomTitle {
    color: #000;
    font-weight: 500;
    font-size: 1.8rem !important;

    a {
      color: #000;
    }

    span[class*="fa-"] {
      margin: 0 0.5rem;
      font-size: 1.8rem;
    }
  }

  .classroomTitle + .restoreButton {
    margin-left: 25px;
  }

  .hiddenWhenSmall {
    @media(max-width: $break-point-sm) {
      display: none;
    }
  }

  &.pageTop {
    margin: 0;
    box-shadow: none;
    font-size: 1.3rem;
  }
}

.tooltip {
  @include mixins.tooltip();
  &:after {
    left: 100%;
  }
}
