@use "../../../../styles/variables.module" as *;
@use "../../../../styles/zIndex.module" as zIndex;

.container {
  max-width: 600px;
  width: 100%;
  height: 100%;
  background-color: $admin-blue-dark;
  position: fixed;
  color: #fff;
  top: 0;
  left: 0;
  box-shadow: 1px 0 3px #666;
  overflow-y: scroll;

  .topExitButton {
    top: 5px;
    right: 5px;
    position: absolute;
    cursor: pointer;

    span {
      font-size: 3rem;
      color: #fff;
    }
  }

  .sidebarHeader {
    padding-top: 10px;
    height: 45px;

    &Light {
      background-color: $admin-green-light !important;

      .topExitButton, h3 {
        color: $admin-blue-dark !important;
      }
    }

    span {
      font-size: 1.5rem;
    }
    a,span {
      color: white;

      &:active {
        color: white;
      }
    }

    .topNavButton {
      padding: 10px 20px;
      cursor: pointer;

      span {
        padding-right: 10px;
        -webkit-text-stroke: 2px $admin-green-dark;
      }

      h3 {
        display: inline-block;
      }
    }
  }

  .accountButton {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;

    button {
      border: 2px solid #fff;
      padding: 10px 20px;
      font-size: 1.6rem;
      color: #fff;
      font-weight: 100;
      cursor: pointer;
      border-radius: 20px;
    }
  }
}

.noAccessContainer {
  text-align: center;
  padding: 0 20px;

  p {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
}
