// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

// border radius

@mixin border-radius($radius:.25em) {
  border-radius: $radius;
}

// antialiasing mode font rendering

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// clearfix

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}

// color tint and shade

@function shade(
    $color,
    $percent
  ) {

  @return mix(#000, $color, $percent);
}
@function tint(
    $color,
    $percent
  ) {

  @return mix(#fff, $color, $percent);
}
