@use "../../../styles/variables.module" as *;
@use "../../../styles/zIndex.module" as zIndex;

.container {
  display: none;
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  font-size: 3rem;
  text-align: center;
  color: white;
  z-index: zIndex.$z-index-header-shortcuts;
  transition: opacity .5s ease;
  max-width: 75px;

  @media (min-width: $break-point-lg) {
    display: block;
  }

  ul {
    list-style: none;
    padding: 1rem 10px;
    margin: 0;
    background: rgba(0, 0, 0, .7);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  > div button {
    color: $black;
  }

}

.shortcutContainer {
  position: relative;

  .popup {
    display: none;
    position: absolute;
    right: calc(100%);
    bottom: 50%;
    transform: translateY(50%);
    background: white;
    font-size: 1.4rem;
    color: $black;
    opacity: 1;
    border: 1px solid $black;

    &Title {
      right: 175%
    }

    > div {
      background: white;
      padding: .5rem;
      border-radius: 5px;
      max-width: 70vw !important;
      overflow-wrap: break-word;
      width: max-content;

      button:nth-of-type(2) {
        color: $error-red;
      }
    }
  }

  > a:focus {
    .popup {
      display: block;
    }
  }

  &:hover {
    .popup {
      display: block;
    }
  }

  > a, > button {
    color: white;
  }

  a {
    color: white;
  }
}

.icon {
  transition: font-size, margin-left, cubic-bezier(0.25, 1, 0.5, 1) 300ms;
}

.draggable {
  cursor: move;
}

.reorder {
  border: 3px solid $gray;
  border-radius: 5px;
}

.icon:hover {
  opacity: .7;
  font-size: 135%;
  margin-left: -35%;
}

/* Bottom */
.icon:hover + .icon {
  font-size: 120%;
  margin-left: -15%;
}

/* Top */
.icon:has(+ .icon:hover){
  font-size: 120%;
  margin-left: -15%;
}


